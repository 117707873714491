import React from "react";
import { graphql } from "gatsby";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import Img from "gatsby-image";
import SEO from "../SEO";
import {FacebookShareButton,FacebookIcon,TwitterIcon,TwitterShareButton} from "react-share" 
import Sidebar from "../Wrappers/SideBar"
import "./Blog.scss"
import "katex/dist/katex.min.css"

export default function BlogPost(props) {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const quiz = props.data.quizJson;
  const post = props.data.markdownRemark;
  const url = "https://www.pluggdriven.se"+props.path;
  const image = post.frontmatter.featuredImage?.childImageSharp.fluid;

  return (
    <>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        article={true}
        image={image?.src}
      />
      <Navbar />
      <div className="">
      <section className="mainSection sectionMaxWidth">
        <div className="mainWrapper"> 
          <article className="blogPost">
            {image && <Img fluid={image} className="blogCardImage" />}
            <div className="blogText">
              <h1>{post.frontmatter.title}</h1>
              <small>
                {post.frontmatter.author},{" "}
                {post.frontmatter.date &&
                  new Date(post.frontmatter.date).toLocaleDateString('sv-SV', options)}
              </small>
              <br />
              <br />
              <div dangerouslySetInnerHTML={{ __html: post.html }} />
              <div className="shareButtons">
              <FacebookShareButton url={url} className="share">
                <FacebookIcon size={32} round={true}/>
              </FacebookShareButton>
              <TwitterShareButton url={url} title={post.frontmatter.title} className="share">
                <TwitterIcon size={32} round={true}/>
              </TwitterShareButton>
              </div>
              <br/>
              <br/>
            </div>
        
          </article>
        </div>
        <Sidebar quiz={quiz}/>

        </section>

      </div>
      <Footer dark={true} />
    </>
  );
}
export const query = graphql`
  query BlogQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date
        author
        description
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    },
    quizJson(url: { eq: $slug }) {
      id
      flashcard
      description
      creator
      title
      questions {
        options
        answer
        text
      }
    }
  }
`;
